import {
  Col,
  Csku,
  Row,
  Tabs,
  Text,
  Theme,
  themeOptions,
} from "@commonsku/styles";
import ErrorBoundary from "../components/ErrorBoundary";
import Header from "./Header";
import MainSection from "./MainSection";
import React, { useEffect, useMemo } from "react";
import { GlobalStyle } from "./DashboardApp";
import { useDispatch, useSelector } from "react-redux";
import { IPublicViewTemplateData } from "../interfaces/PublicViewTemplateData";
import { getIdentityUtils, oauth } from "../utils";
import {
  createFetchPublicViewTemplates,
  createUpdateTemplate,
} from "../actions/shop";
import { useIdentity } from "../hooks";
import { getPopups } from "../selectors";
import Overlay from "../components/Overlay";
import TemplateTile from "../components/shop/TemplateTile/TemplateTile";
import {
  addDefaultTemplateOrderTypes,
  removeDefaultTemplateOrderTypes,
  setTemplateOrderTypes,
} from "../redux/templateOrderTypesSlice";

export type PublicViewTemplateOrderType = {
  public_view_template_order_type_id: string;
  public_view_template_id: string;
  order_type: string;
  is_default: number;
};

export interface TemplatesData extends IPublicViewTemplateData {
  company_id: string | null;
}

const ShopTemplatesApp = () => {
  const popups = useSelector(getPopups);
  const identity = useIdentity();
  const isTeam = getIdentityUtils(identity).isTeam();

  const dispatch = useDispatch();
  useEffect(() => {
    oauth("GET", "public-view-template", {
      include_layouts: true,
      include_commonsku_templates: true,
      status: "ALL",
    }).then(({ json }) => {
      const layouts = json.layouts ?? [];
      const templates = json.templates ?? [];
      const commonsku_templates = json.commonsku_templates ?? [];
      const publicViewTemplates = [
        ...layouts,
        ...templates,
        ...commonsku_templates,
      ];
      dispatch(createFetchPublicViewTemplates(publicViewTemplates));
      dispatch(setTemplateOrderTypes(json?.order_types));
    });
  }, []);

  const templateOrderTypes = useSelector(
    (s: { entities?: { templateOrderTypes: PublicViewTemplateOrderType[] } }) =>
      s?.entities?.templateOrderTypes || []
  );

  const onAddDefaultTemplate = async (
    public_view_template_id: string,
    order_types: string[]
  ) => {
    const resp: any = await dispatch(
      createUpdateTemplate(public_view_template_id, {
        default_order_types: order_types,
      })
    );

    dispatch(
      addDefaultTemplateOrderTypes({
        order_types: order_types,
        orderTypesResponse: resp.payload?.order_types || [],
      })
    );
  };

  const onRemoveDefaultTemplate = async (
    public_view_template_id: string,
    order_types: string[]
  ) => {
    await dispatch(
      createUpdateTemplate(public_view_template_id, {
        remove_default_order_types: order_types,
      })
    );
    dispatch(
      removeDefaultTemplateOrderTypes({
        public_view_template_id: public_view_template_id,
        order_types: order_types,
      })
    );
  };

  const publicViewTemplates = useSelector(
    (state: any) => state.entities.public_view_templates
  );

  const templates = useMemo(
    () =>
      Array.isArray(publicViewTemplates)
        ? publicViewTemplates
        : Object.values(publicViewTemplates),
    [publicViewTemplates]
  );

  const shopDefaultTemplate = useMemo(() => {
    const shopDefaultTemplateIds = templateOrderTypes
      .filter((v) => v.order_type === "SHOP" && +v.is_default === 1)
      .map((v) => v.public_view_template_id);
    return templates.find(
      (t) =>
        t.template_type !== "LAYOUT" &&
        shopDefaultTemplateIds.includes(t.public_view_template_id)
    );
  }, [templates, templateOrderTypes]);
  const shopDefaultTemplateId = shopDefaultTemplate?.public_view_template_id;

  const templateNames = useMemo(() => {
    return templates.map((template) => template.public_view_template_title);
  }, [templates]);

  const userTemplates = (
    <TemplateTile
      templates={templates.filter(
        (template: TemplatesData) =>
          template.template_type !== "LAYOUT" && template.company_id
      )}
      onRemoveDefaultTemplate={onRemoveDefaultTemplate}
      onAddDefaultTemplate={onAddDefaultTemplate}
      shopDefaultTemplateId={shopDefaultTemplateId}
      templateNames={templateNames}
      isTeam={isTeam}
    />
  );

  const internalTemplates = (
    <Tabs
      padded={true}
      variant={"secondary"}
      tabs={[
        {
          label: `Published Templates`,
          content: (
            <TemplateTile
              templates={templates.filter(
                (template: TemplatesData) =>
                  template.status === "PUBLISHED" &&
                  template.template_type !== "LAYOUT" &&
                  !template.company_id
              )}
              onRemoveDefaultTemplate={onRemoveDefaultTemplate}
              onAddDefaultTemplate={onAddDefaultTemplate}
              templateNames={templateNames}
              isTeam={isTeam}
            />
          ),
        },
        {
          label: "Draft Templates",
          content: (
            <TemplateTile
              templates={templates.filter(
                (template: TemplatesData) =>
                  template.status === "DRAFT" &&
                  template.template_type !== "LAYOUT" &&
                  !template.company_id
              )}
              onRemoveDefaultTemplate={onRemoveDefaultTemplate}
              onAddDefaultTemplate={onAddDefaultTemplate}
              templateNames={templateNames}
              isTeam={isTeam}
            />
          ),
        },
        {
          label: "Layouts",
          content: (
            <TemplateTile
              templates={templates.filter(
                (template: TemplatesData) => template.template_type === "LAYOUT"
              )}
              onRemoveDefaultTemplate={onRemoveDefaultTemplate}
              onAddDefaultTemplate={onAddDefaultTemplate}
              templateNames={templateNames}
              isTeam={isTeam}
              tab="layouts"
            />
          ),
        },
      ]}
    />
  );

  return (
    <Theme theme={themeOptions}>
      <GlobalStyle />
      <ErrorBoundary>
        <div
          style={{ background: "var(--color-neutrals-20)", minHeight: "100vh" }}
        >
          <Header />
          <MainSection popups={popups}>
            <Csku as={"div"} className="main-content">
              <Row>
                <Col
                  padded
                  xs={12}
                  md={12}
                  style={{ padding: 16, paddingTop: 0 }}
                >
                  <Row>
                    <Col xs style={{ paddingLeft: 8 }}>
                      <Text
                        as="p"
                        style={{
                          color: "var(--color-neutrals-90)",
                          fontFamily: "var(--font-family-bold)",
                          fontSize: 32,
                        }}
                      >
                        Manage Templates
                      </Text>
                      {isTeam ? (
                        <Text
                          as="p"
                          style={{
                            fontFamily: "var(--font-family-regular)",
                            fontSize: 18,
                          }}
                        >
                          The templates listed here are templates that can be
                          applied to Presentations or Shops. Published templates
                          are live for commonsku users to see and use and drafts
                          are only for commonsku employees to see.
                        </Text>
                      ) : (
                        <Text
                          as="p"
                          style={{
                            fontFamily: "var(--font-family-regular)",
                            fontSize: 18,
                          }}
                        >
                          The templates listed here are templates that can be
                          applied to Shops. Set a default template by clicking
                          the three dots menu. If you are looking for Project
                          Templates, they can be found{" "}
                          <a
                            href="/admin_setting_project_templates.php"
                            target="_blank"
                          >
                            here
                          </a>
                          .
                        </Text>
                      )}
                    </Col>
                    {!isTeam && userTemplates}
                    {isTeam && internalTemplates}
                  </Row>
                </Col>
              </Row>
            </Csku>
          </MainSection>
        </div>
        <Overlay popups={popups} />
      </ErrorBoundary>
    </Theme>
  );
};

export default ShopTemplatesApp;
